<!--
 * @Author: your name
 * @Date: 2022-04-01 10:22:33
 * @LastEditTime: 2022-06-07 15:03:25
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\App.vue
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

import { getWxConfig } from "@/untils/distubritionIndex.js";
var wx = require('weixin-js-sdk') || window['wx'];
export default {
  data() {
    return{
      isIos:''
    }
  },
  created(){
    // if(isAndroid()){
    //    if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
    //            this.handleFontSize()
    //        } else {
    //            if (document.addEventListener) {
    //                document.addEventListener("WeixinJSBridgeReady", this.handleFontSize, false);
    //            } else if (document.attachEvent) {
    //                document.attachEvent("WeixinJSBridgeReady", this.handleFontSize);
    //                document.attachEvent("onWeixinJSBridgeReady", this.handleFontSize);  }
    //        }
    // }
  },
  created(){
      //  const u = navigator.userAgent;
      //   this.isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      //   console.log(this.isIos);
        // this.shareFn();
  },
  methods:{
    // 如果是安卓手机，禁止在微信内更改字体大小
    handleFontSize(){
        // 设置网页字体为默认大小
         WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
        // 重写设置网页字体大小的事件
         WeixinJSBridge.on('menu:setfont', function() {
            WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
        })
    },

    shareFn() {
            var u = location.href.split("#")[0]
            console.log(u);
            let data = {
                shareurl: u,
                url: u
            }
            getWxConfig({ data }).then(res => {
                // console.log(res,'这是res');
                this.jsSDK = res.data.data.data
                this.titleInfo = res.data.data.contentArr
                wx.config({
                    debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                    appId: this.jsSDK.appid, // 必填，公众号的唯一标识
                    timestamp: this.jsSDK.timestamp, // 必填，生成签名的时间戳
                    nonceStr: this.jsSDK.nonceStr, // 必填，生成签名的随机串
                    signature: this.jsSDK.signature,// 必填，签名
                    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', "onMenuShareTimeline",
                        'onMenuShareAppMessage'] // 必填，需要使用的 JS 接口列表
                });
                let phone = JSON.parse(window.sessionStorage.getItem('UserInfo')).mobile
                // console.log( location.protocol+"//"+location.host+'/share/wechat/resumeDetail/',);
                let sp = JSON.parse(window.sessionStorage.getItem('UserInfo')).sp
                // this.sp = sp
                wx.ready(()=> {

                    wx.updateAppMessageShareData({
                                title: this.titleInfo.title, // 分享标题
                                desc: this.titleInfo.info, // 分享描述
                                link: `https://iot.yuna6.com/bigFlowPage?from=2&sp=${sp}&p_openid=&invite_phone=${phone}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: this.titleInfo.image, // 分享图标
                                success: function (res) {
                                    // console.log(res)
                                    // 设置成功
                                }
                            })

                            wx.updateTimelineShareData({
                                title: this.titleInfo.title, // 分享标题
                                link: `https://iot.yuna6.com/bigFlowPage?from=2&sp=${sp}&p_openid=&invite_phone=${phone}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: this.titleInfo.image, // 分享图标
                                success: function () {
                                    // 设置成功
                                }
                            })
                });
            })


        },

  },
  watch:{
    $route: {
      handler: function (val, oldVal) {
        // console.log(val.name);
          if(val.name == 'distributionIndex'||
              val.name == 'bigFlowPage') {
             this.shareFn()
          }
      
      },
      deep: true
    }
  }
}
</script>
<style>
</style>
