import { get, post } from "./request.js"

// 我的基本信息
export const getUserInfo = (params) => {
  return post({ url: "api/v1.Distribution/userInfo", data: params ? params.data : {} })
}

//用户列表
export const queryUserList = (params) => {
  return post({ url: "api/v1.Distribution/queryUserList", data: params ? params.data : {} })
}

//用户列表
export const queryUserListAll = (params) => {
  return post({ url: "api/v1.Distribution/queryUserListAll", data: params ? params.data : {} })
}


//收益列表
export const FinanceList = (params) => {
  return post({ url: "api/v1.Distribution/FinanceList", data: params ? params.data : {} })
}
//修改用户备注
export const saveUserRemark = (params) => {
  return post({ url: "api/v1.Distribution/saveUserRemark", data: params ? params.data : {} })
}

// http://connect.yuna6.cn/api/v1.Distribution/settingMoney
//修改自定分佣
export const settingMoney = (params) => {
  return post({ url: "api/v1.Distribution/settingMoney", data: params ? params.data : {} })
}
// http://connect.yuna6.cn/api/v1.Distribution/getTimeData
//获取时间列表
export const getTimeData = (params) => {
  return post({ url: "api/v1.Distribution/getTimeData", data: params ? params.data : {} })
}

// http://connect.yuna6.cn/api/v1.Distribution/addUser
// 新增 用户
export const addUser = (params) => {
  return post({ url: "api/v1.Distribution/addUser", data: params ? params.data : {} })
}

// http://connect.yuna6.cn/api/v1.Distribution/cardList
// 卡片列表
export const cardList = (params) => {
  return post({ url: "api/v1.Distribution/cardList", data: params ? params.data : {} })
}

// http://connect.yuna6.cn/api/v1.Distribution/cardList
// 卡片列表 自提发货
export const cardListSelf = (params) => {
  return post({ url: "api/v1.Distribution/lifting", data: params ? params.data : {} })
}








// http://connect.yuna6.cn/api/v1.Distribution/send
//发送验证码
export const send = (params) => {
  return post({ url: "api/v1.Distribution/send", data: params ? params.data : {} })
}

// http://connect.yuna6.cn/api/v1.Distribution/mobileLogin?
// 用户登录

export const mobileLogin = (params) => {
  return post({ url: "api/v1.Distribution/mobileLogin", data: params ? params.data : {} })
}
// http://connect.yuna6.cn/api/v1.Distribution/cardInfo
// 卡片详情
export const cardInfo = (params) => {
  return post({ url: "api/v1.Distribution/cardInfo", data: params ? params.data : {} })
}
// http://connect.yuna6.cn/api/v1.Distribution/import
export const importFile = (params) => {
  return post({ url: "api/v1.Distribution/import", data: params })
}

// {{url}}api/v1.Distribution/addCard
//添加银行卡
export const addCard = (params) => {
  return post({ url: "api/v1.Distribution/addCard", data: params ? params.data : {} })
}

//银行卡列表
export const getCardList = (params) => {
  return post({ url: "api/v1.Distribution/getCardList", data: params ? params.data : {} })
}
// {{url}}api/v1.Distribution/cardDelete 
// 删除银行卡
export const cardDelete = (params) => {
  return post({ url: "api/v1.Distribution/cardDelete", data: params ? params.data : {} })
}

// {{url}}api/v1.Distribution/cardSave
// 修改银行卡
export const cardSave = (params) => {
  return post({ url: "api/v1.Distribution/cardSave", data: params ? params.data : {} })
}
// 导出
// api/v1.order/exportData
export const exportData = (params) => {
  return post({ url: "api/v1.order/exportData", data: params ? params.data : {} })
}

// 查询用户searchName
export const searchName = (params) => {
  return post({ url: "api/v1.order/searchName", data: params ? params.data : {} })
}

// api/v1.order/exportList
// 导出列表
export const exportList = (params) => {
  return post({ url: "api/v1.order/exportList", data: params ? params.data : {} })
}

// exportDelete 删除
export const exportDelete = (params) => {
  return post({ url: "api/v1.order/exportDelete", data: params ? params.data : {} })
}


// 分销商替用户下单  接口https://connect.yuna6.com/distriOrder  post请求  参数 'name','phone','province','city','area','address','invite_phone'(分销商手机号)
export const distriOrder = (params) => {
  return post({ url: "distriOrder", data: params ? params.data : {} })
}


// 查询是否实名了
export const getRealStatus = (params) => {
  return get({ url: "getRealStatus", data: params ? params.data : {} })
}

// 分配卡片列表
//api/v1.User/getIccidList
export const getIccidList = (params) => {
  return get({ url: "api/v1.User/getIccidList", data: params ? params.data : {} })
}

// 分配卡片
// api/v1.User/distributionIccid
export const distributionIccid = (params) => {
  return post({ url: "api/v1.User/distributionIccid", data: params ? params.data : {} })
}
// /api/v1.User/getWxConfig
export const getWxConfig = (params) => {
  return post({ url: "api/v1.User/getWxConfig", data: params ? params.data : {} })
}

//       // https://connect.yuna6.com/detail?sp=8e296a067a37563370ded05f5a3bf3ec
export const getCardDetail = (params) => {
  return post({ url: "detail", data: params ? params.data : {} })
}

//  下单  
// buyCard
export const buyCard = (params) => {
  return post({ url: "buyCard", data: params ? params.data : {} })
}
// getThisOpenid
export const getThisOpenid = (params) => {
  return post({ url: "getThisOpenid", data: params ? params.data : {} })
}

// getCouponsList
export const getCouponsList = (params) => {
  return get({ url: "getCouponsList", data: params ? params.data : {} })
}

// sendVerifyCode
export const sendVerifyCode = (params) => {
  return post({ url: "sendVerifyCode", data: params ? params.data : {} })
}
// v1.User/cardOrderList
export const cardOrderList = (params) => {
  return post({ url: "api/v1.User/OrderList", data: params ? params.data : {} })
}

// getOrderId

export const getOrderId = (params) => {
  return post({ url: "getOrderId", data: params ? params.data : {} })
}

// getBuyUrlScheme

export const getBuyUrlScheme = (params) => {
  return get({ url: "getBuyUrlScheme", data: params ? params.data : {} })
}



export default {
  getBuyUrlScheme,
  getOrderId,
  cardOrderList,
  sendVerifyCode,
  getCouponsList,
  getThisOpenid,
  buyCard,
  getCardDetail,
  getWxConfig,
  distributionIccid,
  getIccidList,
  getRealStatus,
  distriOrder,
  exportDelete,
  exportList,
  queryUserListAll,
  searchName,
  exportData,
  getUserInfo,
  queryUserList,
  FinanceList,
  saveUserRemark,
  settingMoney,
  getTimeData,
  cardList,
  send,
  cardInfo,
  importFile,
  addCard,
  getCardList,
  cardDelete,
  cardSave

} 