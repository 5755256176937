 /*
 * @Author: your name
 * @Date: 2022-02-08 16:38:11
 * @LastEditTime: 2022-04-09 14:48:42
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue-shoping\src\untils\request.js
 */
import Axios from "axios"
// const baseURL = "https://wifi.yunruiot.com/"
const baseURL = "https://connect.yuna6.com/"
// const baseURL = "http://192.168.20.17/api"
// const baseURL = "http://192.168.30.217"

import qs from "qs"
export const instance = Axios.create({
  baseURL: baseURL,
  timeout: 300000,
  headers: { 'Content-Type': 'application/json' }
});
// axios的相应拦截器
// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  if (error.response.data.message == "Unauthenticated." && error.response.status == 401) {

  }
  // console.log(error.response.status,'错误信息')
  // console.log(error.response.data,'错误信息')
  // console.log(error.response.headers,'错误信息')
  return Promise.reject(error);
});
// 获取本地存储的token
// 如果需要添加token,就给请求头部添加token
const setHeaderToken = (isNeedToken) => {
  let accessToken = window.localStorage.getItem("access_token")
  if (isNeedToken) { // api 请求需要携带 access_token 
    if (!accessToken) {
      // router.push({
      // 	name: 'pho_login',
      // });
      // window.loginShow = true
      // vm.$root.loginShow = true
      // this_.$refs.login.show = true;
    }
    instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  }
}

export const get = (params) => {
  setHeaderToken(params.token)
  return instance({
    url: params.url,
    method: "get",
    params: params.data ? params.data : {}
  })
}
export const post = (params) => {
  setHeaderToken(params.token)
  return instance({
    url: params.url,
    method: "post",
    data: params.data ? qs.stringify(params.data) : {},
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    }
  })
}
export const newinstance = Axios.create({
  baseURL: "https://connect.yuna6.com/newapi/news/",
  timeout: 300000,
  headers: { 'Content-Type': 'application/json' }
})
export const newget = (params) => {
  setHeaderToken(params.token)
  return newinstance({
    url: params.url,
    method: "get",
    params: params.data ? params.data : {}
  })
}
export default { get, post, newget }
